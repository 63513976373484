// add class active so the header become yellow after scrolling
$(window).on("scroll", function() {
    if($(window).scrollTop() > 50) {
        $(".header").addClass("active");
        $(".logo").addClass('scroll');
        $(".logo .scroll").css('display', 'none');
        $(".logo .no-scroll").css('display', 'block');
    } else {
        //remove the background property so it comes transparent again (defined in your css)
       $(".header").removeClass("active");
       $(".logo").removeClass('scroll');
       $(".logo .scroll").css('display', 'block');
       $(".logo .no-scroll").css('display', 'none');
    }
});


// adding offset of the header to the breadcrumb
// $(window).bind("load", function() {
//   var headerHeight = $('.header').height();
//
//   headerHeight = headerHeight + 20;
//
//   $('.sub-nav-container').css('padding-top', headerHeight);
// });


$( document ).ready(function() {
  $('.overlay-loading').fadeOut();
});


$('header button[data-action="toggle-content"]').on('click', function() {
  $('.content').toggleClass( "show" );
});

$('header .loop-mobile').on('click', function() {
  $('.toggle-nav-modal .search-bar-from .search-bar').toggleClass( "open" );
});

$(document).ready(function () {
  var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  if (isChrome) {
      setTimeout(function () {
        if (window.location.hash) {
          var hash = window.location.hash;
          window.location.hash = "";
          window.location.hash = hash;
        }
      }, 500);
  }
});
