$(".element-list-wrapper").on('click', '.view-more a', () => {
  $('.view-more').hide();
  $('.post-item.hidden').show();
  $('.post-item:nth-child(4)').show();
  $('.pagination').show(1, () => {
    kafe.ext.foundation.equalizer({
      wrapper: $('.element-list .post-item').closest('element-list'),
      item:    '.element-list-item',
      rows:    {
        small:  2,
        medium: 3
      }
    });
  });
});