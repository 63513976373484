
if ($(window).width() < 992) {
  $('.directory-list-subnav ul').css('display', 'none');
  $('.directory-list-subnav .all-certif').css('display', 'none');

  $('.directory-list-subnav h3').addClass('btn btn--blue color--white w-100');


  $('.directory-list-subnav .certifications h3').on('click', function() {
    $('.directory-list-subnav .certifications .all-certif').slideToggle();
  });

  $('.directory-list-subnav .all-category h3').on('click', function() {
    $('.directory-list-subnav .all-category ul').slideToggle();
  });

  $('.directory-list-subnav .category-region h3').on('click', function() {
    $('.directory-list-subnav .category-region ul').slideToggle();
  });

  $('.directory-list-subnav .sub-category h3').on('click', function() {
    $('.directory-list-subnav .sub-category ul').slideToggle();
  });
}
