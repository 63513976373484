// put the right region img on hover of the region name
$( document ).ready(function() {
    var regionClass = '';

    $('.block-region .link').on('mouseover' ,function() {

      $('.block-region__img').removeClass(regionClass);

      var region = $(this).data('region');
      regionClass = 'region--' + region;

      $('.block-region__img').addClass(regionClass);
    });
});
